let intv_dont_leave, intv_home_sentences

const title = document.title
const otherTitle = "Adé merci"
document.addEventListener("visibilitychange", function (event) {
  if (document.hidden) {
    intv_dont_leave = window.setInterval(function () {
      document.title = document.title === otherTitle ? title : otherTitle
    }, 1000)
  } else {
    window.clearInterval(intv_dont_leave)
    document.title = title
  }
})

if (document.getElementById("homeMessages")) {
  let curHM = 0
  let sentences = ["Du bisch guet,<br>mir sind gueter."]
  let setHomeSentences = () => {
    curHM += 1
    if (curHM >= sentences.length) curHM = 0
    document.getElementById("homeMessages").innerHTML = sentences[curHM]
  }

  intv_home_sentences = window.setInterval(setHomeSentences, 2000)
}
